import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import currentRouteMixin from '@/mixins/current-route.js'
import modalComponent from '@/components/Modal'

export default {
    name: 'UsersProfile',
    data() {
        return {
            user: {},
            editableFieldName: null,
            editableFieldValue: null,
            editableFieldNameForChildren: null,
            editableFieldValueForChildren: null,
            currentUserId: null,
            newPassword: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout,
        modalComponent
    },
    mounted() {
        this.currentUserId = this.currentRoute().params.id

        this.getUser()
    },
    methods: {
        getUser() {
            const self = this;
            const appends = '?append=name';
            const includes = '&include=childrens';
    
            httpAxios({
                url: this.$backendUrl + '/api/v1/users/' + this.currentUserId + appends + includes,
                method: 'GET'
            }).then(response => {
                self.user = response.data.user;
            })
        },
        prepareEditField(field) {
            this.editableFieldName = field
            this.editableFieldValue = this.user[field]
        },
        cancelEditField() {
            this.editableFieldName = null
            this.editableFieldValue = null
        },
        updateField() {
            const self = this;
            const data = { };
            data[this.editableFieldName] = this.editableFieldValue;

            httpAxios({
                url: this.$backendUrl + '/api/v1/users/' + this.currentUserId,
                method: 'PUT',
                data: data
            }).then(function(response) {
                self.user[self.editableFieldName] = response.data.user[self.editableFieldName]
                self.cancelEditField()  
            })
        },
        updatePassword() {
            const self = this;
            const data = { new_password : this.newPassword };

            httpAxios({
                url: this.$backendUrl + '/api/v1/users/change-password/' + this.currentUserId,
                method: 'PUT',
                data: data
            }).then(function() {
                self.newPassword = null;
            })
        },
        deleteUser() {
            const self = this;

            httpAxios({
                url: this.$backendUrl + '/api/v1/users/' + this.currentUserId,
                method: 'DELETE'
            }).then(function() {
                self.$router.push({ name: 'admin.users' })
            })
        }
    }
}
